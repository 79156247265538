.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(128, 128, 128, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.header {
  box-shadow: 0 1.5px 4px -2px gray;
  background-color: white;
  height: 85px;
  transition: height 0.3s ease;
}

.header-scrolled {
  height: 50px;
  transition: height 0.3s ease;
  padding: 0 !important;
}

.header-text-color {
  color: var(--primary-blue) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  transition: font-size 0.3s ease;
}

.font-scrolled { 
  font-size: 16px !important;
  transition: font-size 0.3s ease;
}

.header-text-color:hover {
  color: #2828ad !important;
}

.header-toggle-color {
  color: var(--primary-blue) !important;
}

.footer {
  width: 100%;
  background-color: white;
  color: var(--primary-blue) !important;
}

.phrase {
  font-size: 13px;
  font-weight: 500;
  
  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.copyright {
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 8px;
  }
}

.transition-width {
  transition: width 0.3s ease;
}

.carousel {
  height: 90vh;
}

@media (max-width: 768px) {
  .carousel {
    height: 80vh;
  }
}

.item-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img-carousel {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  height: auto;
  width: auto;
  max-width: none;
  max-height: none;
}

.caption-content {
  display: inline-block;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  padding: 10px;
}

.product-button {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: none;
  background-color: var(--primary-blue);
  color: #343a40;
  margin: 0 30px 10px 30px;
  color: white;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.product-button:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.product-button-span {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  color: var(--dark-primary-blue);
  font-size: 20px;
  font-weight: 500;
}

.button-whats-modal {
  display: flex;
  align-items: center;
}

.text-button-modal {
  margin: 0 0 0 5px;
}

.text-products {
  margin-bottom: 20px;
  font-size: 18px;
  width: 80%;
  text-align: center;
}

.footer-modal { 
  justify-content: space-between !important;
}

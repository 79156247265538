.google-maps-card {
  width: 40%;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    height: 210px;
    margin-top: 40px;
  }
}

.google-maps-card iframe {
  width: 100%;
  height: 90%;
  border: 1.5px solid var(--gray-text);
  border-radius: 8px;
}

.address-text {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  color: var(--secondary-blue);
  font-size: 15px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 10px;
  }
}
